import React, { useState } from 'react';
import {
    Button, Col, Form, Row,
} from 'antd';
import Empty from 'antd/lib/empty';
import Text from 'antd/lib/typography/Text';
import { LabelOptColor } from './common';
import ConstructorCheckboxItem from './constructor-checkbox-item';
import CVATTooltip from '../common/cvat-tooltip';

interface CommonLabelsFormProps {
    commonLabels: LabelOptColor[];
    onCancel: () => void;
    onSubmit: (selectedLabels: LabelOptColor[]) => void;
}

const groupLabelsByProject = (labels: LabelOptColor[]):
{ [projectId: number]: { projectName: string, labels: LabelOptColor[] } } => labels.reduce((groups, label) => {
    const { project_id: projectId, project_name: projectName = '' } = label;
    if (!projectId) return groups;
    if (!groups[projectId]) {
        groups[projectId] = { projectName, labels: [] };
    }
    groups[projectId].labels.push(label);
    return groups;
}, {} as { [projectId: number]: { projectName: string, labels: LabelOptColor[] } });

function CommonLabelsForm({ commonLabels, onCancel, onSubmit }: CommonLabelsFormProps): JSX.Element {
    const [selectedLabels, setSelectedLabels] = useState<LabelOptColor[]>([]);

    const handleCheckboxChange = (label: LabelOptColor, checked: boolean): void => {
        setSelectedLabels((prev) => {
            if (checked) {
                return [...prev.filter((l) => l.name !== label.name), label];
            }
            return prev.filter((l) => l.name !== label.name || l.project_id !== label.project_id);
        });
    };

    const isSelected = (label: LabelOptColor): boolean => selectedLabels.some(
        (selectedLabel) => selectedLabel.name === label.name && selectedLabel.project_id === label.project_id,
    );

    const groupedLabels = groupLabelsByProject(commonLabels);

    return (
        <Form
            initialValues={{}}
            onFinish={() => onSubmit(selectedLabels)}
            layout='vertical'
        >
            {Object.keys(groupedLabels).map((projectIdString, index) => {
                const projectId = Number(projectIdString);
                const project = groupedLabels[projectId];
                return (
                    <div key={projectId}>
                        <Row justify='start' align='middle'>
                            <Text
                                className='cvat-text-color'
                                italic
                                strong
                                style={{ fontSize: '14px', marginLeft: '8px', marginTop: '8px' }}
                            >
                                {project.projectName}
                            </Text>
                        </Row>
                        <Row justify='start' align='middle' className='cvat-constructor-common-labels-form' style={{ paddingTop: '0' }}>
                            {project.labels.map((label: LabelOptColor) => (
                                <ConstructorCheckboxItem
                                    key={`${label.name}-${label.project_id}`}
                                    label={label}
                                    color={label.color}
                                    checked={isSelected(label)}
                                    onChange={handleCheckboxChange}
                                />
                            ))}
                        </Row>
                        {index < Object.keys(groupedLabels).length - 1 &&
                            <hr style={{ padding: '0px', margin: '0px' }} />}

                    </div>
                );
            })}
            {commonLabels.length === 0 && (
                <Row justify='center' align='middle'>
                    <Col>
                        <Empty description='No labels to import' />
                    </Col>
                </Row>
            )}
            <Row justify='start' align='middle'>
                <Col>
                    <CVATTooltip title='Import selected labels'>
                        <Button
                            className='cvat-submit-new-label-button'
                            style={{ width: '150px' }}
                            type='primary'
                            htmlType='submit'
                            disabled={!selectedLabels.length}
                        >
                            {selectedLabels.length ?
                                `Import ${selectedLabels.length} label${selectedLabels.length > 1 ? 's' : ''}` :
                                'Import labels'}
                        </Button>
                    </CVATTooltip>
                </Col>
                <Col offset={1}>
                    <CVATTooltip title='Do not import labels and return to the previous step'>
                        <Button
                            className='cvat-cancel-new-label-button'
                            type='primary'
                            danger
                            style={{ width: '150px' }}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </CVATTooltip>
                </Col>
            </Row>
        </Form>
    );
}

export default CommonLabelsForm;
