import { IDBPDatabase, openDB } from 'idb';

interface FrameDB {
    frames: {
        key: number;
        value: Blob | ImageData;
    };
    chunks: {
        key: number;
        value: ArrayBuffer;
    };
}

export type FrameIDB = IDBPDatabase<FrameDB>;

export async function initDB(jid: number): Promise<IDBPDatabase<FrameDB>> {
    return openDB<FrameDB>(`frameDB_${jid}`, 1, {
        upgrade(db) {
            console.log('upgrade', jid);
            db.createObjectStore('frames');
            db.createObjectStore('chunks');
        },
        blocked(currentVersion: number, blockedVersion: number | null, event: IDBVersionChangeEvent) {
            console.log('blocked', jid, currentVersion, blockedVersion, event);
        },
        blocking(currentVersion: number, blockingVersion: number | null, event: IDBVersionChangeEvent) {
            console.log('blocking', jid, currentVersion, blockingVersion, event);
        },
        terminated() {
            console.log('terminated', jid);
        },
    });
}

export async function putIndexedData<K extends keyof FrameDB>(
    db: IDBPDatabase<FrameDB>,
    storeName: K,
    key: number,
    data: FrameDB[K]['value'],
): Promise<IDBValidKey> {
    return db.put(storeName, data, key);
}

export async function getIndexedData<K extends keyof FrameDB>(
    db: IDBPDatabase<FrameDB>,
    storeName: K,
    key: number,
): Promise<FrameDB[K]['value']> {
    return db.get(storeName, key);
}

export async function getAllIndexedKeys(
    db: IDBPDatabase<FrameDB>,
    storeName: keyof FrameDB,
): Promise<number[]> {
    return (await db.getAllKeys(storeName))
        ?.map((key) => Number(key));
}

class FrameDBPool {
    private dbPool: Map<number, IDBPDatabase<FrameDB>> = new Map();

    public async getDB(jid: number, disableCache = false): Promise<IDBPDatabase<FrameDB>> {
        if (jid === -1) {
            throw new Error('jid is -1');
        }
        if (!disableCache && this.dbPool.has(jid)) {
            return this.dbPool.get(jid) as IDBPDatabase<FrameDB>;
        }
        const db = await initDB(jid);
        if (!disableCache) this.dbPool.set(jid, db);
        return db;
    }
}

export const frameDBPool = new FrameDBPool();
