import React from 'react';
import Text from 'antd/lib/typography/Text';
import config from 'config';
import { LabelOptColor } from './common';

interface ConstructorCheckboxItemProps {
    label: LabelOptColor;
    color?: string;
    checked: boolean;
    onChange: (label: LabelOptColor, checked: boolean) => void;
}

export default function ConstructorCheckboxItem(props: ConstructorCheckboxItemProps): JSX.Element {
    const {
        color, label, checked, onChange,
    } = props;

    const handleLabelClick = (): void => {
        onChange(label, !checked);
    };

    const backgroundColor = color || config.NEW_LABEL_COLOR;
    let textColor = '#ffffff';
    try {
        // convert color to grayscale and from the result get better text color
        // (for darken background -> lighter text, etc.)
        const [r, g, b] = [backgroundColor.slice(1, 3), backgroundColor.slice(3, 5), backgroundColor.slice(5, 7)];
        const grayscale = (parseInt(r, 16) + parseInt(g, 16) + parseInt(b, 16)) / 3;
        if (grayscale - 128 >= 0) {
            textColor = '#000000';
        }
    } catch (_: any) {
        // nothing to do
    }

    return (
        <div
            style={{
                background: backgroundColor,
                opacity: checked ? 1 : undefined,
                cursor: 'pointer',
                border: checked ? '2px solid #1890ff' : undefined,
            }}
            className='cvat-constructor-viewer-item'
            onClick={handleLabelClick}
            onKeyPress={(): boolean => false}
            role='button'
            tabIndex={0}
        >
            <Text style={{ color: textColor }}>{label.name}</Text>
        </div>
    );
}
