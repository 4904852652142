import React, { useEffect, useState } from 'react';
import { Select, Tag } from 'antd';
import debounce from 'lodash/debounce';

import { getCore } from 'cvat-core-wrapper';
import { User } from './user-selector';

const { Option } = Select;
const core = getCore();

export interface Review {
    reviewer: User;
    completed: boolean;
}

interface Props {
    value: Review[] | null;
    username?: string;
    className?: string;
    onSelect: (reviewer: Review[] | null) => void;
}

const searchReviews = debounce(
    (
        searchValue: string,
        setReviews: React.Dispatch<React.SetStateAction<Review[]>>,
        initialReviews: Review[],
    ): void => {
        core.users
            .get({
                search: searchValue,
                limit: 10,
                is_active: true,
                page_size: 'all',
            })
            .then((result: User[]) => {
                if (result) {
                    const reviews = result.map((user: User) => ({
                        reviewer: user,
                        completed: initialReviews.find(
                            (review) => review.reviewer.id === user.id,
                        )?.completed ?? false,
                    }));
                    setReviews((prevReviews: Review[]) => [
                        ...reviews,
                        ...prevReviews.filter((review) => !reviews.find(
                            (r) => r.reviewer.id === review.reviewer.id,
                        )),
                    ]);
                }
            });
    },
    250,
    {
        maxWait: 750,
    },
);

const ReviewerMultiSelector: React.FC<Props> = (props: Props) => {
    const {
        value, className, username, onSelect,
    } = props;
    const [searchPhrase, setSearchPhrase] = useState(username || '');
    const [initialReviews, setInitialReviews] = useState<Review[]>([]);
    const [reviews, setReviews] = useState<Review[]>([]);

    useEffect(() => {
        core.users.get({ limit: 10, is_active: true, page_size: 'all' }).then((result: User[]) => {
            if (result) {
                setInitialReviews(result.map(
                    (user) => ({
                        reviewer: user,
                        completed: value?.find((review) => review.reviewer.id === user.id)?.completed ?? false,
                    }),
                ));
            }
        });
    }, []);

    useEffect(() => {
        setReviews(initialReviews);
    }, [initialReviews]);

    useEffect(() => {
        if (searchPhrase) {
            searchReviews(searchPhrase, setReviews, initialReviews);
        } else {
            setReviews(initialReviews);
        }
    }, [searchPhrase]);

    const handleSelect = (_value: any): void => {
        const selectedReviews = _value ?
            reviews.filter((_review) => _value.includes(_review.reviewer.id.toString())) :
            null;
        onSelect(selectedReviews);
    };

    const combinedClassName = className ? `${className} cvat-user-search-field` : 'cvat-user-search-field';
    return (
        <Select
            mode='multiple'
            allowClear
            className={combinedClassName}
            placeholder='Select users'
            value={value?.map((review) => review.reviewer.id.toString())}
            onChange={handleSelect}
            onSearch={setSearchPhrase}
            filterOption={(input, option) => (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())}
            tagRender={(_props) => {
                const fullLabel = (_props?.label ?? '') as string;
                const completed = fullLabel.endsWith('✓');
                return (
                    <Tag
                        key={_props?.value}
                        closable={_props?.closable}
                        onClose={_props?.onClose}
                        color={completed ? 'green' : 'red'}
                    >
                        {fullLabel}
                    </Tag>
                );
            }}
        >
            {reviews.map((review) => (
                <Option
                    key={review.reviewer.id}
                    value={review.reviewer.id.toString()}
                >
                    {`${review.reviewer.username} ${review.completed ? '✓' : ''}`}
                </Option>
            ))}
        </Select>
    );
};

export default ReviewerMultiSelector;
