import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

export default function useD3(renderChartFn: any, dependencies: any): any {
    const ref = useRef<any>();
    useEffect(() => (
        renderChartFn(d3.select(ref.current)) ?? (() => {})
    ), dependencies);
    return ref;
}
