import React, { useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

function ExternalVideoComponent({ url, numFrames }: { url: string, numFrames: number }): JSX.Element {
    const [currentTime, setCurrentTime] = useState(0);
    const [fps, setFps] = useState(0);
    const videoRef = useRef<HTMLVideoElement>(null);

    // Get total length of video
    const handleTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement>) => {
        setCurrentTime(event.currentTarget.currentTime);
        console.log(event.currentTarget);
    };

    const handleLoadedMetadata = (): void => {
        if (videoRef.current) {
            setFps(numFrames / videoRef.current.duration);
        }
    };

    /* eslint-disable jsx-a11y/media-has-caption */
    return (
        <div>
            <h1>
                Current frame:
                {Math.round(currentTime * fps)}
            </h1>
            <video
                ref={videoRef}
                width='100%'
                height='100%'
                controls
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                // onPause={handleOnPause}
            >
                <source src={url} type='video/mp4' />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export const openExternalVideoWindow = (url: string, numFrames: number): void => {
    const win = window.open('', '_blank');
    if (win) {
        const root = createRoot(win.document.body);
        root.render(<ExternalVideoComponent url={url} numFrames={numFrames} />);
    }
};
