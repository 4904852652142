import React, { useEffect, useState } from 'react';
import Autocomplete from 'antd/lib/auto-complete';
import { getCore } from 'cvat-core-wrapper';

const core = getCore();

type Props = {
    onChange: (id: string | null) => void;
};

export default function PatientIdentifierSearchField(props: Props): JSX.Element {
    const { onChange } = props;
    const [patientIDs, setPatientIDs] = useState([]);

    useEffect(() => {
        core.patientIDs.get().then((result) => {
            const options = result.map((d) => ({
                value: d.value,
            }));

            setPatientIDs(options);
        });
    }, []);

    return (
        <Autocomplete
            // value={searchPhrase}
            placeholder='Choose existing ID, create new, or leave blank. ID Format: PREFIX-XXXX'
            onChange={onChange}
            className='cvat-project-search-field'
            options={patientIDs}
            filterOption='true'
        />
    );
}
