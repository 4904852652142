import React, { useEffect, useRef, useState } from 'react';
import { Job, Task } from 'cvat-core/src/session';
import { FrameData } from 'cvat-core/src/frames';
import Text from 'antd/lib/typography';
import { Col, Row } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import { ReloadOutlined } from '@ant-design/icons';
import { Box } from 'cvat-canvas/src/typescript/shared';
import { Point } from '../../utils/math';
import CVATTooltip from '../common/cvat-tooltip';

interface Props {
    task: Task;
    onUpdateJob(jobInstance: Job): void;
}

enum ActiveDragHandle {
    NONE = 'none',
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}

class ImageAreaSelector {
    private canvas: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D;
    private frame: ImageBitmap;
    private thickness: number;
    private radius: number;
    private activeDragHandle: ActiveDragHandle;
    private box: Box;
    private resizable: boolean;

    constructor(canvas: HTMLCanvasElement, frame: ImageBitmap, resizable: boolean, box?: Box | null) {
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d')!;
        this.frame = frame;
        this.box = box ?? {
            xtl: 0,
            ytl: 0,
            xbr: frame.width,
            ybr: frame.height,
        };
        const uiMult = Math.round(frame.width / 1000);
        this.resizable = resizable;
        this.radius = 10 * uiMult;
        this.thickness = 2 * uiMult;
        this.activeDragHandle = ActiveDragHandle.NONE;

        this.canvas.width = this.frame.width;
        this.canvas.height = this.frame.height;

        this.ctx.canvas.onmousedown = this.mouseDown.bind(this);
        this.ctx.canvas.onmousemove = this.mouseMove.bind(this);
        this.ctx.canvas.onmouseup = this.mouseUp.bind(this);
        this.draw();
    }

    get video_area(): Box {
        return {
            xtl: Math.round(Math.min(this.box.xtl, this.box.xbr)),
            ytl: Math.round(Math.min(this.box.ytl, this.box.ybr)),
            xbr: Math.round(Math.max(this.box.xtl, this.box.xbr)),
            ybr: Math.round(Math.max(this.box.ytl, this.box.ybr)),
        };
    }

    set video_area(box: Box) {
        this.box = box;
        this.draw();
    }

    get middleX(): number {
        return (this.box.xtl + this.box.xbr) / 2;
    }

    get middleY(): number {
        return (this.box.ytl + this.box.ybr) / 2;
    }

    get arcs(): [number, number][] {
        return [
            [this.box.xtl, this.middleY],
            [this.middleX, this.box.ytl],
            [this.box.xbr, this.middleY],
            [this.middleX, this.box.ybr],
        ];
    }

    public resetToFrame(): void {
        this.box = {
            xtl: 0,
            ytl: 0,
            xbr: this.frame.width,
            ybr: this.frame.height,
        };
        this.draw();
    }

    private inRange(p: Point, origin: Point, radius: number): boolean {
        const dx = p.x - origin.x;
        const dy = p.y - origin.y;
        return dx * dx + dy * dy <= radius * radius;
    }

    private updateActiveDragHandle(p: Point): void {
        if (this.resizable) {
            if (this.inRange(p, { x: this.box.xtl, y: this.middleY }, this.radius)) {
                this.activeDragHandle = ActiveDragHandle.LEFT;
            } else if (this.inRange(p, { x: this.middleX, y: this.box.ytl }, this.radius)) {
                this.activeDragHandle = ActiveDragHandle.TOP;
            } else if (this.inRange(p, { x: this.box.xbr, y: this.middleY }, this.radius)) {
                this.activeDragHandle = ActiveDragHandle.RIGHT;
            } else if (this.inRange(p, { x: this.middleX, y: this.box.ybr }, this.radius)) {
                this.activeDragHandle = ActiveDragHandle.BOTTOM;
            } else {
                this.activeDragHandle = ActiveDragHandle.NONE;
            }
        }
    }

    private getMousePos(event: MouseEvent): Point {
        const rect = this.canvas.getBoundingClientRect();
        const x = (this.ctx.canvas.width * (event.clientX - rect.left)) / this.ctx.canvas.offsetWidth;
        const y = (this.ctx.canvas.height * (event.clientY - rect.top)) / this.ctx.canvas.offsetHeight;
        return { x, y };
    }

    private mouseDown(event: MouseEvent): void {
        const { x, y } = this.getMousePos(event);
        this.updateActiveDragHandle({ x, y });
    }

    private mouseMove(event: MouseEvent): void {
        if (this.activeDragHandle === ActiveDragHandle.NONE) {
            return;
        }

        const { x, y } = this.getMousePos(event);

        if (this.activeDragHandle === ActiveDragHandle.LEFT) {
            this.box.xtl = x;
        } else if (this.activeDragHandle === ActiveDragHandle.TOP) {
            this.box.ytl = y;
        } else if (this.activeDragHandle === ActiveDragHandle.RIGHT) {
            this.box.xbr = x;
        } else if (this.activeDragHandle === ActiveDragHandle.BOTTOM) {
            this.box.ybr = y;
        }

        this.draw();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private mouseUp(event: MouseEvent): void {
        this.activeDragHandle = ActiveDragHandle.NONE;
    }

    draw(): void {
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        this.ctx.drawImage(this.frame, 0, 0);
        this.ctx.strokeStyle = 'purple';
        this.ctx.lineWidth = this.thickness;
        this.ctx.strokeRect(this.box.xtl, this.box.ytl, this.box.xbr - this.box.xtl, this.box.ybr - this.box.ytl);

        if (this.resizable) {
            for (const [x, y] of this.arcs) {
                this.ctx.beginPath();
                this.ctx.arc(x, y, this.radius, 0, 2 * Math.PI);
                this.ctx.stroke();
            }
        }
    }
}

function VideoAreaComponent(props: Props): JSX.Element {
    const {
        task: taskInstance,
        onUpdateJob,
    } = props;

    const taskSize = taskInstance.size;
    const { jobs } = taskInstance;
    const currentVideoArea = jobs?.[0]?.video_area;

    const [randomFrame, setRandomFrame] = useState<number | null>(null);
    const [manualUpdate, setManualUpdate] = useState<number>(0);
    const [frame, setFrame] = useState<ImageBitmap | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imageAreaSelectorRef = useRef<ImageAreaSelector | null>(null);

    useEffect(() => {
        const rnd = Math.floor(Math.random() * taskSize);
        setRandomFrame(rnd);
    }, [taskSize, manualUpdate]);

    useEffect(() => {
        if (randomFrame !== null) {
            taskInstance.frames.get(randomFrame).then((frameData: FrameData) => {
                frameData.data().then((data: ImageBitmap | Blob) => {
                    // @ts-ignore
                    setFrame(data?.imageData as ImageBitmap | null);
                });
            });
        }
    }, [randomFrame]);

    useEffect(() => {
        if (frame && canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            imageAreaSelectorRef.current = new ImageAreaSelector(
                canvas,
                frame,
                true,
                imageAreaSelectorRef.current?.video_area ?? (currentVideoArea && { ...currentVideoArea }),
            );
        }
    }, [frame]);

    return (
        <div className='cvat-video-area-wrapper'>
            <Row>
                <Button
                    onClick={() => setManualUpdate(manualUpdate + 1)}
                    icon={<ReloadOutlined />}
                >
                    Random frame
                </Button>
                <CVATTooltip
                    title='Dicards changes and puts the video area to the value currently registered in the database'
                >
                    <Button
                        type='dashed'
                        disabled={!currentVideoArea}
                        onClick={() => {
                            if (currentVideoArea && imageAreaSelectorRef.current) {
                                imageAreaSelectorRef.current.video_area = { ...currentVideoArea };
                            }
                        }}
                    >
                        Discard changes
                    </Button>
                </CVATTooltip>
                <CVATTooltip title='Resets the area the full view'>
                    <Button
                        type='dashed'
                        disabled={!currentVideoArea}
                        onClick={() => {
                            if (imageAreaSelectorRef.current) {
                                imageAreaSelectorRef.current.resetToFrame();
                            }
                        }}
                    >
                        Reset
                    </Button>
                </CVATTooltip>
                <Button
                    type='primary'
                    onClick={() => {
                        const currentBox = imageAreaSelectorRef.current?.video_area ?? null;
                        for (const job of jobs) {
                            job.video_area = currentBox;
                            onUpdateJob(job);
                        }
                    }}
                >
                    Save
                </Button>
            </Row>
            <Row>
                <Col>
                    <Text style={{ marginTop: 8 }}>
                        Registered video area:
                        {currentVideoArea ? `
                            (${currentVideoArea.xtl}, ${currentVideoArea.ytl}) -
                            (${currentVideoArea.xbr}, ${currentVideoArea.ybr})
                        ` : 'not set'}
                    </Text>
                </Col>
            </Row>
            <Row>
                <canvas
                    id='video-area-canvas'
                    ref={canvasRef}
                    style={{
                        width: '100%',
                        height: '100%',
                        marginTop: '10px',
                    }}
                />
            </Row>
        </div>
    );
}

export default React.memo(VideoAreaComponent);
