// Copyright (C) 2021-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { Mutex } from 'async-mutex';
import { MP4Reader, Bytestream } from './3rdparty/mp4';
import {
    frameDBPool, FrameIDB, getIndexedData, putIndexedData,
} from './indexed-preload';

export class RequestOutdatedError extends Error {}

export enum BlockType {
    MP4VIDEO = 'mp4video',
    ARCHIVE = 'archive',
}

export enum ChunkQuality {
    ORIGINAL = 'original',
    COMPRESSED = 'compressed',
}

export enum DimensionType {
    DIMENSION_3D = '3d',
    DIMENSION_2D = '2d',
}

interface RawFramesMetaData {
    chunk_size: number;
    deleted_frames: number[];
    included_frames: number[];
    frame_filter: string;
    frames: {
        width: number;
        height: number;
        name: string;
        related_files: number;
    }[];
    image_quality: number;
    size: number;
    start_frame: number;
    stop_frame: number;
}

export function decodeContextImages(
    block: any, start: number, end: number, jid = -1, decodedCallback?: (frame: number) => void,
): Promise<Record<string, ImageBitmap>> {
    const decodeZipWorker = (decodeContextImages as any).zipWorker || new Worker(
        new URL('./unzip_imgs.worker', import.meta.url),
    );
    (decodeContextImages as any).zipWorker = decodeZipWorker;
    return new Promise((resolve, reject) => {
        decodeContextImages.mutex.acquire().then((release) => {
            const result: Record<string, ImageBitmap> = {};
            let decoded = 0;

            decodeZipWorker.onerror = (event: ErrorEvent) => {
                release();
                reject(event.error);
            };

            decodeZipWorker.onmessage = async (event) => {
                if (event.data.error) {
                    this.zipWorker.onerror(new ErrorEvent('error', {
                        error: event.data.error,
                    }));
                    return;
                }

                const { data, fileName } = event.data;
                result[fileName.split('.')[0]] = data;
                decoded++;

                if (decodedCallback) {
                    decodedCallback(decoded);
                }

                if (decoded === end - start) {
                    release();
                    resolve(result);
                }
            };

            decodeZipWorker.postMessage({
                block,
                start,
                end,
                dimension: DimensionType.DIMENSION_2D,
                dimension2D: DimensionType.DIMENSION_2D,
                jid,
                disableStore: false,
            });
        });
    });
}

decodeContextImages.mutex = new Mutex();

export function decodeVideoChunks(
    videoData: ArrayBuffer, start: number, end: number,
    meta: RawFramesMetaData,
    mode: 'annotation' | 'interpolation',
    jid = -1,
    decodedCallback?: (frame: number) => void,
): Promise<Record<string, ImageBitmap>> {
    const videoWorker = new H264Decoder() as any as Worker;

    return new Promise((resolve, reject) => {
        const result: Record<string, ImageBitmap> = {};
        let decoded = 0;

        videoWorker.onerror = (e: ErrorEvent) => {
            videoWorker.terminate();
            reject(new Error(`Video cannot be decoded. ${e.message}`));
        };

        videoWorker.onmessage = async (event) => {
            if (event.data.consoleLog) {
                // ignore initialization message
                return;
            }

            const keptIndex = start + decoded;

            let frameMeta = null;
            if (mode === 'interpolation' && meta.frames.length === 1) {
                // video tasks have 1 frame info, but image tasks will have many infos
                [frameMeta] = meta.frames;
            } else if (mode === 'annotation' || (mode === 'interpolation' && meta.frames.length > 1)) {
                if (keptIndex > meta.stop_frame) {
                    throw new Error(`Meta information about frame ${keptIndex} can't be received from the server`);
                }
                frameMeta = meta.frames[keptIndex - start];
            } else {
                throw new Error(`Invalid mode is specified ${mode}`);
            }

            const renderHeight = frameMeta.height;
            const renderWidth = frameMeta.width;
            const scaleFactor = Math.ceil(renderHeight / event.data.height);
            const height = Math.round(renderHeight / scaleFactor);
            const width = Math.round(renderWidth / scaleFactor);

            const loadChunk = async (db?: FrameIDB): Promise<void> => {
                let imageData: ImageData | null = null;
                if (db) imageData = await getIndexedData(db, 'frames', keptIndex) as ImageData;

                if (!imageData) {
                    const array = new Uint8ClampedArray(event.data.buf.slice(0, width * height * 4));
                    imageData = new ImageData(array, width);

                    if (db) await putIndexedData(db, 'frames', keptIndex, imageData);
                }

                result[keptIndex.toString()] = await createImageBitmap(imageData);

                if (decodedCallback) {
                    decodedCallback(keptIndex - start);
                }

                if (keptIndex === end - 1) {
                    resolve(result);
                    videoWorker.terminate();
                }
            };

            frameDBPool.getDB(jid).then((db) => {
                loadChunk(db);
            });

            decoded++;
        };

        videoWorker.postMessage({
            type: 'Broadway.js - Worker init',
            options: {
                rgb: true,
                reuseMemory: false,
            },
        });

        const reader = new MP4Reader(new Bytestream(videoData));
        reader.read();
        const video = reader.tracks[1];

        const avc = video.trak.mdia.minf.stbl.stsd.avc1.avcC;
        const sps = avc.sps[0];
        const pps = avc.pps[0];

        videoWorker.postMessage({ buf: sps, offset: 0, length: sps.length });
        videoWorker.postMessage({ buf: pps, offset: 0, length: pps.length });

        for (let sample = 0; sample < video.getSampleCount(); sample++) {
            video.getSampleNALUnits(sample).forEach((nal) => {
                videoWorker.postMessage({ buf: nal, offset: 0, length: nal.length });
            });
        }
    });
}

interface BlockToDecode {
    start: number;
    end: number;
    block: ArrayBuffer;
    onDecodeAll(): void;
    onDecode(frame: number, bitmap: ImageBitmap | Blob): void;
    onReject(e: Error): void;
}

export class FrameDecoder {
    private blockType: BlockType;
    private chunkSize: number;
    /*
        ImageBitmap when decode zip or video chunks
        Blob when 3D dimension
        null when not decoded yet
    */
    private decodedChunks: Record<number, Record<number, ImageBitmap | Blob>>;
    private chunkIsBeingDecoded: BlockToDecode | null;
    private requestedChunkToDecode: BlockToDecode | null;
    private orderedStack: number[];
    private mutex: Mutex;
    private dimension: DimensionType;
    private cachedChunksLimit: number;
    // used for video chunks to get correct side after decoding
    private renderWidth: number;
    private renderHeight: number;
    private zipWorker: Worker;

    constructor(
        blockType: BlockType,
        chunkSize: number,
        cachedBlockCount: number,
        dimension: DimensionType = DimensionType.DIMENSION_2D,
        thumbnail = false,
        jid = -1,
        disableStore = false,
    ) {
        this.mutex = new Mutex();
        this.orderedStack = [];

        this.cachedChunksLimit = Math.max(1, cachedBlockCount);
        this.dimension = dimension;

        this.renderWidth = 1920;
        this.renderHeight = 1080;
        this.chunkSize = chunkSize;
        this.blockType = blockType;

        this.decodedChunks = {};
        this._thumbnail = thumbnail;
        this._jid = jid;
        this._disableStore = disableStore;
        this.requestedChunkToDecode = null;
        this.chunkIsBeingDecoded = null;
    }

    isChunkCached(chunkNumber: number): boolean {
        return chunkNumber in this.decodedChunks;
    }

    hasFreeSpace(): boolean {
        return Object.keys(this.decodedChunks).length < this.cachedChunksLimit;
    }

    cleanup(extra = 1): void {
        // argument allows us to specify how many chunks we want to write after clear
        const chunks = Object.keys(this.decodedChunks).map((chunk: string) => +chunk);
        let { length } = chunks;
        while (length > this.cachedChunksLimit - Math.min(extra, this.cachedChunksLimit)) {
            const lastChunk = this.orderedStack.pop();
            if (typeof lastChunk === 'undefined') {
                return;
            }
            delete this.decodedChunks[lastChunk];
            length--;
        }
    }

    requestDecodeBlock(
        block: ArrayBuffer,
        start: number,
        end: number,
        onDecode: (frame: number, bitmap: ImageBitmap | Blob) => void,
        onDecodeAll: () => void,
        onReject: (e: Error) => void,
    ): void {
        if (this.requestedChunkToDecode !== null) {
            // a chunk was already requested to be decoded, but decoding didn't start yet
            if (start === this.requestedChunkToDecode.start && end === this.requestedChunkToDecode.end) {
                // it was the same chunk
                this.requestedChunkToDecode.onReject(new RequestOutdatedError());

                this.requestedChunkToDecode.onDecode = onDecode;
                this.requestedChunkToDecode.onReject = onReject;
            } else if (this.requestedChunkToDecode.onReject) {
                // it was other chunk
                this.requestedChunkToDecode.onReject(new RequestOutdatedError());
            }
        } else if (this.chunkIsBeingDecoded === null || this.chunkIsBeingDecoded.start !== start) {
            // everything was decoded or decoding other chunk is in process
            this.requestedChunkToDecode = {
                block,
                start,
                end,
                onDecode,
                onDecodeAll,
                onReject,
            };
        } else {
            // the same chunk is being decoded right now
            // reject previous decoding request
            this.chunkIsBeingDecoded.onReject(new RequestOutdatedError());

            this.chunkIsBeingDecoded.onReject = onReject;
            this.chunkIsBeingDecoded.onDecode = onDecode;
        }

        this.startDecode();
    }

    setRenderSize(width: number, height: number): void {
        this.renderWidth = width;
        this.renderHeight = height;
    }

    frame(frameNumber: number): ImageBitmap | Blob | null {
        const chunkNumber = Math.floor(frameNumber / this.chunkSize);
        if (chunkNumber in this.decodedChunks) {
            return this.decodedChunks[chunkNumber][frameNumber];
        }

        return null;
    }

    static cropImage(
        imageBuffer: ArrayBuffer,
        imageWidth: number,
        imageHeight: number,
        width: number,
        height: number,
    ): ImageData {
        if (width === imageWidth && height === imageHeight) {
            return new ImageData(new Uint8ClampedArray(imageBuffer), width, height);
        }
        const source = new Uint32Array(imageBuffer);

        const bufferSize = width * height * 4;
        if (imageWidth === width) {
            return new ImageData(new Uint8ClampedArray(imageBuffer, 0, bufferSize), width, height);
        }

        const buffer = new ArrayBuffer(bufferSize);
        const rgbaInt32 = new Uint32Array(buffer);
        const rgbaInt8Clamped = new Uint8ClampedArray(buffer);
        let writeIdx = 0;
        for (let row = 0; row < height; row++) {
            const start = row * imageWidth;
            rgbaInt32.set(source.subarray(start, start + width), writeIdx);
            writeIdx += width;
        }

        return new ImageData(rgbaInt8Clamped, width, height);
    }

    async startDecode(): Promise<void> {
        const blockToDecode = { ...this.requestedChunkToDecode };
        const release = await this.mutex.acquire();
        try {
            const { start, end, block } = this.requestedChunkToDecode;
            if (start !== blockToDecode.start) {
                // request is not relevant, another block was already requested
                // it happens when A is being decoded, B comes and wait for mutex, C comes and wait for mutex
                // B is not necessary anymore, because C already was requested
                blockToDecode.onReject(new RequestOutdatedError());
                throw new RequestOutdatedError();
            }

            const chunkNumber = Math.floor(start / this.chunkSize);
            this.orderedStack = [chunkNumber, ...this.orderedStack];
            this.cleanup();
            const decodedFrames: Record<number, ImageBitmap | Blob> = {};
            this.chunkIsBeingDecoded = this.requestedChunkToDecode;
            this.requestedChunkToDecode = null;

            if (this.blockType === BlockType.MP4VIDEO) {
                const worker = new Worker(
                    new URL('./3rdparty/Decoder.worker', import.meta.url),
                );
                let index = start;

                worker.onmessage = (e) => {
                    if (e.data.consoleLog) {
                        // ignore initialization message
                        return;
                    }
                    const keptIndex = index;

                    // do not use e.data.height and e.data.width because they might be not correct
                    // instead, try to understand real height and width of decoded image via scale factor
                    const scaleFactor = Math.ceil(this.renderHeight / e.data.height);
                    const height = Math.round(this.renderHeight / scaleFactor);
                    const width = Math.round(this.renderWidth / scaleFactor);

                    const loadChunk = async (db?: FrameIDB): Promise<void> => {
                        let imageData: ImageData | null = null;
                        if (db) imageData = await getIndexedData(db, 'frames', keptIndex) as ImageData;
                        if (!imageData) {
                            imageData = FrameDecoder.cropImage(
                                e.data.buf,
                                e.data.width,
                                e.data.height,
                                width,
                                height,
                            );
                            if (db) await putIndexedData(db, 'frames', keptIndex, imageData);
                        }
                        decodedFrames[keptIndex] = await createImageBitmap(imageData);
                        this.chunkIsBeingDecoded.onDecode(keptIndex, decodedFrames[keptIndex]);

                        if (keptIndex === end) {
                            this.decodedChunks[chunkNumber] = decodedFrames;
                            this.chunkIsBeingDecoded.onDecodeAll();
                            this.chunkIsBeingDecoded = null;
                            worker.terminate();
                            release();
                        }
                    };

                    if (!this._disableStore && this._jid !== -1) {
                        frameDBPool.getDB(this._jid).then((db) => {
                            loadChunk(db);
                        });
                    } else {
                        loadChunk();
                    }

                    index++;
                };

                worker.onerror = (event: ErrorEvent) => {
                    release();
                    worker.terminate();
                    this.chunkIsBeingDecoded.onReject(event.error);
                    this.chunkIsBeingDecoded = null;
                };

                worker.postMessage({
                    type: 'Broadway.js - Worker init',
                    options: {
                        rgb: true,
                        reuseMemory: false,
                    },
                });

                const reader = new MP4Reader(new Bytestream(block));
                reader.read();
                const video = reader.tracks[1];

                const avc = reader.tracks[1].trak.mdia.minf.stbl.stsd.avc1.avcC;
                const sps = avc.sps[0];
                const pps = avc.pps[0];

                worker.postMessage({ buf: sps, offset: 0, length: sps.length });
                worker.postMessage({ buf: pps, offset: 0, length: pps.length });

                for (let sample = 0; sample < video.getSampleCount(); sample++) {
                    video.getSampleNALUnits(sample).forEach((nal) => {
                        worker.postMessage({ buf: nal, offset: 0, length: nal.length });
                    });
                }
            } else {
                this.zipWorker = this.zipWorker || new Worker(
                    new URL('./unzip_imgs.worker', import.meta.url),
                );
                let index = start;

                this.zipWorker.onmessage = async (event) => {
                    if (event.data.error) {
                        this.zipWorker.onerror(new ErrorEvent('error', {
                            error: event.data.error,
                        }));
                        return;
                    }

                    if (this._thumbnail) {
                        event.data.data = URL.createObjectURL(event.data.data);
                    }

                    decodedFrames[event.data.index] = event.data.data as ImageBitmap | Blob;
                    this.chunkIsBeingDecoded.onDecode(event.data.index, decodedFrames[event.data.index]);

                    if (index === end) {
                        this.decodedChunks[chunkNumber] = decodedFrames;
                        this.chunkIsBeingDecoded.onDecodeAll();
                        this.chunkIsBeingDecoded = null;
                        release();
                    }
                    index++;
                };

                this.zipWorker.onerror = (event: ErrorEvent) => {
                    release();
                    this.chunkIsBeingDecoded.onReject(event.error);
                    this.chunkIsBeingDecoded = null;
                };

                this.zipWorker.postMessage({
                    block,
                    start,
                    end,
                    dimension: this.dimension,
                    dimension2D: DimensionType.DIMENSION_2D,
                    thumbnail: this._thumbnail,
                    jid: this._jid,
                    disableStore: this._disableStore,
                });
            }
        } catch (error) {
            this.chunkIsBeingDecoded = null;
            release();
        }
    }

    public cachedChunks(includeInProgress = false): number[] {
        const chunkIsBeingDecoded = includeInProgress && this.chunkIsBeingDecoded ?
            Math.floor(this.chunkIsBeingDecoded.start / this.chunkSize) : null;
        return Object.keys(this.decodedChunks).map((chunkNumber: string) => +chunkNumber).concat(
            ...(chunkIsBeingDecoded !== null ? [chunkIsBeingDecoded] : []),
        ).sort((a, b) => a - b);
    }
}

// export index-preload functions
export * as IDBPreload from './indexed-preload';
