import './styles.scss';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import * as d3 from 'd3';
import { IDBPreload } from 'cvat-core/src/server-proxy';
import { Label, Attribute as LabelAttribute } from 'cvat-core/src/labels';
import ObjectState, { SerializedData } from 'cvat-core/src/object-state';
import { ObjectType } from 'cvat-core/src/enums';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd/lib/grid';
import Select from 'antd/lib/select';
import Text from 'antd/lib/typography/Text';
import Checkbox from 'antd/lib/checkbox';
import { LoadingOutlined } from '@ant-design/icons';
import CVATTooltip from '../../common/cvat-tooltip';
import LabelSelector from '../../label-selector/label-selector';
import { getCore, Job, ShapeType } from '../../../cvat-core-wrapper';
import { changeFrameAsync, createAnnotationsAsync, updateAnnotationsAsync } from '../../../actions/annotation-actions';
import useD3 from './useD3';
import { CombinedState } from '../../../reducers';
import useThumbnails from './useThumbnails';

const cvat = getCore();

interface Segment {
    start: number;
    end: number;
    color: string;
    line?: number;
    label?: string;
    value?: string;
}

interface Attribute {
    spec_id: number;
    value: string;
}

interface Shape {
    id: number;
    frame: number;
    attributes: Attribute[];
    outside: boolean;
    points?: number[];
    type: string;
}

interface Track {
    id: number;
    label_id: number;
    frame: number;
    shapes: Shape[];
    attributes: Attribute[];
}

interface CursorData {
    frame: number;
    xLocal: d3.ScaleLinear<number, number>;
}

interface FrameData {
    frame: number;
    zone: number;
}

type AttrMap = {
    [key in number | string]: string;
};

class TimelineData {
    private readonly startFrame: number;
    private readonly stopFrame: number;
    private readonly frameCount: number;
    private readonly labelMap: Map<number, Label>;
    private readonly colors: string[];
    private segments: Segment[] = [];

    constructor(startFrame: number, stopFrame: number, labelMap: Map<number, Label>, colors: string[]) {
        this.startFrame = startFrame;
        this.stopFrame = stopFrame;
        this.frameCount = stopFrame - startFrame;
        this.labelMap = labelMap;
        this.colors = colors;
    }

    get data(): Segment[] {
        return this.segments;
    }

    private attributeColor(attr_name: string): string {
        // hash the attribute name to get a number
        const hash = attr_name.split('').reduce((a, b) => {
            // eslint-disable-next-line no-bitwise
            const c = ((a << 5) - a) + b.charCodeAt(0);
            // eslint-disable-next-line no-bitwise
            return c & c;
        }, 0);
        // use the hash to get an index into the color array
        const index = Math.abs(hash) % this.colors.length;
        return this.colors[index];
    }

    private getLine(start: number, end: number): number {
        let line = 0;
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        while (this.segments.some((segment) => segment.line === line && segment.start < end && segment.end > start)) {
            line++;
        }
        return line;
    }

    public computeLines(): this {
        for (const segment of this.segments) {
            if (segment.line === undefined) {
                segment.line = this.getLine(segment.start, segment.end);
            }
        }
        return this;
    }

    public addTrack(track: Track): void {
        const label = this.labelMap.get(track.label_id);
        if (label?.name === 'imgattr') {
            this.addImgAttrTrack(track);
        } else if (label?.name !== 'videoattr') {
            this.addNormalTrack(track, label);
        }
    }

    private addImgAttrTrack(track: Track, firstLine = 27): void {
        if (track.shapes.length === 0) {
            return;
        }

        const imgattrLabel = this.labelMap.get(track.label_id);
        const attrs = imgattrLabel?.attributes ?? [];
        const attributeCount = attrs.length;
        const currentAttributeValues: string[] = new Array(attributeCount).fill('__undefined__');
        const attributeStartFrame: number[] = new Array(attributeCount).fill(this.startFrame);

        for (const shape of track.shapes) {
            const shapeFrame = shape.frame;
            const shapeAttributes = shape.attributes;

            for (let i = 0; i < attributeCount; i++) {
                const previousAttributeValue = currentAttributeValues[i];
                const attributeValue = shapeAttributes.find(
                    (attr) => attr.spec_id === attrs[i].id,
                )?.value ?? previousAttributeValue;
                if (attributeValue !== previousAttributeValue) {
                    if (previousAttributeValue !== '__undefined__') {
                        this.segments.push({
                            start: attributeStartFrame[i],
                            end: shapeFrame,
                            color: this.attributeColor(`${attrs[i].name}${previousAttributeValue}`),
                            line: i + firstLine,
                            label: 'imgattr',
                            value: `${attrs[i].name}=${previousAttributeValue}`,
                        });
                    }
                    attributeStartFrame[i] = shapeFrame;
                    currentAttributeValues[i] = attributeValue;
                }
            }
        }

        for (let i = 0; i < attributeCount; i++) {
            const attributeValue = currentAttributeValues[i];
            if (attributeValue !== '__undefined__') {
                this.segments.push({
                    start: attributeStartFrame[i],
                    end: this.stopFrame,
                    color: this.attributeColor(`${attrs[i].name}${attributeValue}`),
                    line: i + firstLine,
                    label: 'imgattr',
                    value: `${attrs[i].name}=${attributeValue}`,
                });
            }
        }
    }

    private addNormalTrack(track: Track, label: Label | undefined): void {
        const trackColor = label?.color ?? '#000000';

        let segmentStartFrame = this.startFrame;
        let previousShapeIsOutside = true;

        for (const shape of track.shapes) {
            const currentShapeIsOutside = shape.outside;
            const currentShapeFrame = shape.frame;

            if (currentShapeIsOutside && !previousShapeIsOutside) {
                this.segments.push({
                    start: segmentStartFrame,
                    end: currentShapeFrame,
                    color: trackColor,
                    label: label?.name,
                    value: `id=${track.id}`,
                });
                segmentStartFrame = currentShapeFrame;
            } else if (!currentShapeIsOutside && previousShapeIsOutside) {
                segmentStartFrame = currentShapeFrame;
            }

            previousShapeIsOutside = currentShapeIsOutside;
        }

        if (!previousShapeIsOutside) {
            this.segments.push({
                start: segmentStartFrame,
                end: this.stopFrame,
                color: trackColor,
                label: label?.name,
                value: `id=${track.id}`,
            });
        }
    }
}

const makeSegments = (numArr: number[], color: string): Segment[] => numArr.reduce(
    (acc: Segment[], frame: number, idx: number, arr: number[]): Segment[] => {
        if (idx === 0 || frame !== arr[idx - 1] + 1) {
            acc.push({ start: frame, end: frame, color });
        } else {
            acc[acc.length - 1].end = frame;
        }
        return acc;
    }, [],
);

export default function AnnotationTimelineComponent(): JSX.Element {
    const dispatch = useDispatch();
    const annotation = useSelector((state: CombinedState) => state.annotation);
    const instance = annotation.job.instance as Job;
    const excludedLabels = ['imgattr', 'videoattr'];
    const normalLabels: Label[] = instance.labels.filter((label: Label) => !excludedLabels.includes(label.name));
    const imgAttrLabel: Label = instance.labels.find((label: Label) => label.name === 'imgattr') ?? normalLabels[0];
    const imgAttrs: LabelAttribute[] = imgAttrLabel?.attributes ?? [];
    const videoAttrLabel: Label = instance.labels.find((label: Label) => label.name === 'videoattr') ?? normalLabels[0];
    const { ranges } = annotation.player;
    const bufferedSegments = ranges
        .split(';')
        .map((range: string) => {
            const [start, end] = range.split(':');
            return { start: +start, end: +end, color: 'rgba(110,145,255,0.5)' };
        }).filter((range) => range.start && range.end);

    const [data, setData] = useState<Segment[]>([]);
    const [transform, setTransform] = useState<d3.ZoomTransform>(d3.zoomIdentity);
    const [cursorPos, setCursorPos] = useState<number>(0);
    const [trackStart, setTrackStart] = useState<FrameData | null>(null);
    const [mouseDown, setCursorLock] = useState<boolean>(false);
    const [ctrlPressed, setCtrlPressed] = useState<boolean>(false);
    const [manualUpdate, setManualUpdate] = useState<number>(0);

    const [selectedLabelID, setSelectedLabelID] = useState<number>(normalLabels[0]?.id ?? -1);
    const [selectedImgAttr, setSelectedImgAttr] = useState<LabelAttribute>(imgAttrs[0]);
    const [selectedImgAttrValue, setSelectedImgAttrValue] = useState<string>('__undefined__');

    const [indexedSegments, setIndexedSegments] = useState<Segment[]>([]);

    useEffect(() => {
        IDBPreload.frameDBPool.getDB(instance.id).then((db) => {
            if (db) {
                IDBPreload.getAllIndexedKeys(
                    db,
                    'frames',
                    // () => setManualUpdate((prev) => prev + 1),
                ).then((keys: number[]) => {
                    if (keys === null) {
                        return;
                    }
                    setIndexedSegments(
                        makeSegments(keys.sort((a, b) => a - b), 'rgba(157,157,157,0.5)'),
                    );
                });
            }
        });
    }, [cursorPos, manualUpdate, instance.id]);

    const thumbnails = useThumbnails();

    const labelMap = useMemo((): Map<number, Label> => (
        new Map(instance.labels.map((label: Label) => ([label.id ?? 0, label])))
    ), [instance.labels]);

    const handleDrag = async (x: number): Promise<void> => {
        setCursorPos(x);
        await dispatch(changeFrameAsync(x));
    };

    const createTrack = async (labelID: number, start: number, end: number): Promise<void> => {
        const label = labelMap.get(labelID) ?? normalLabels[0];
        const objectType = ObjectType.TRACK;
        const shapeType = ShapeType.RECTANGLE;

        const serializedData: SerializedData = {
            frame: start,
            label,
            objectType,
            occluded: false,
            outside: false,
            points: [0, 0, 500, 500],
            rotation: 0,
            shapeType,
            zOrder: 0,

            __internal: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                delete(_p1, _p2) {
                    return false;
                },
                save(p1) {
                    return p1;
                },
            },
        };

        const tmpState = new cvat.classes.ObjectState(serializedData);
        await dispatch(createAnnotationsAsync([tmpState]));
        const states = await instance.annotations.get(end);
        states[states.length - 1].outside = true;
        await dispatch(updateAnnotationsAsync(states));
    };
    const updateImgAttr = async (frame: number, value: string, prevState?: ObjectState): Promise<ObjectState> => {
        const states = await instance.annotations.get(frame);
        const state = states.find((state_: ObjectState) => state_.label.id === imgAttrLabel.id);
        const attrs: AttrMap = state?.attributes ?? {};
        const prevAttrs: AttrMap = prevState?.attributes ?? {};
        attrs[selectedImgAttr.id ?? 0] = value;
        state.rotation = 0;
        state.updateFlags.attributes = true;
        state.keyframe = prevState === undefined || Object
            .keys(prevAttrs)
            .some((key) => prevAttrs[key] !== attrs[key]);
        await dispatch(updateAnnotationsAsync(states));
        return state;
    };
    const createImgAttr = async (start: number, end: number): Promise<void> => {
        const restoreValue = (await instance.annotations.get(end)).find(
            (state: ObjectState) => state.label.id === imgAttrLabel.id,
        ).attributes[selectedImgAttr.id ?? 0];
        let prevState = await updateImgAttr(start, selectedImgAttrValue);
        let next = prevState.keyframes?.next ?? null;
        while (next !== null && next < end) {
            prevState = await updateImgAttr(next, selectedImgAttrValue, prevState);
            next = prevState.keyframes?.next ?? null;
        }
        await updateImgAttr(end, restoreValue, prevState);
    };

    const { colors } = annotation;
    const playerFrame = annotation.player.frame.number;
    const N = instance.stopFrame - instance.startFrame;

    useEffect(() => {
        instance.annotations.export().then((res: any) => {
            const timelineData = new TimelineData(instance.startFrame, instance.stopFrame, labelMap, colors);
            for (const track of res.tracks) {
                timelineData.addTrack(track);
            }
            setData(timelineData.computeLines().data);
        });
    }, [
        instance, manualUpdate,
        annotation.annotations.history.undo.length, annotation.annotations.history.redo.length,
        selectedLabelID, selectedImgAttr, selectedImgAttrValue,
        thumbnails.display.state, thumbnails.active.state,
    ]);

    async function createMissingTrack(labelID: number): Promise<void> {
        if (labelID !== 0) {
            const states = await instance.annotations.get(instance.startFrame);
            if (states.find((state: ObjectState) => state.label.id === labelID) === undefined) {
                await createTrack(labelID, instance.startFrame, instance.stopFrame);
            }
        }
    }

    useEffect(() => {
        createMissingTrack(imgAttrLabel?.id ?? 0).then();
    }, [imgAttrLabel]);

    useEffect(() => {
        createMissingTrack(videoAttrLabel?.id ?? 0).then();
    }, [videoAttrLabel]);

    useEffect(() => {
        if (!mouseDown) {
            setCursorPos(playerFrame);
            setManualUpdate((prevState) => prevState + 1);
        }
    }, [playerFrame, mouseDown]);

    const width = 2000;
    const height = 240;
    const padding = 20;
    const thumbnailSize = 100;
    const thumbnailCount = width / thumbnailSize;
    const fstart = instance.startFrame;
    const fstop = instance.stopFrame;

    const drawTimeline = useCallback((chart: any) => {
        const minX = padding;
        const maxX = width - padding;
        const tickN = Math.min(N, 25);

        const xGlob = d3
            .scaleLinear()
            .domain([fstart, fstop])
            .range([minX, maxX]);
        const x = transform.rescaleX(xGlob);
        const xAxis = d3.axisTop(x).ticks(tickN).tickSizeOuter(0);
        let currentTransform = transform;
        let ctrlMode = ctrlPressed;
        let currentTrackStart = trackStart;
        let currentCursorPos = cursorPos;

        const getSpacedIndices = (xScale: d3.ScaleLinear<number, number>): number[] => {
            const begin = Math.floor(xScale.invert(padding));
            const end = Math.floor(xScale.invert(width - padding));
            const targetStep = (end - begin) / thumbnailCount;
            const stepLog = Math.floor(Math.log10(targetStep));
            const step = Math.max(10 ** stepLog * Math.ceil(targetStep / 10 ** stepLog), 1);
            const rBegin = Math.floor(begin / step) * step;
            const rEnd = Math.floor(end / step) * step;
            return Array.from({ length: (rEnd - rBegin) / step + 1 }, (_, i) => rBegin + i * step);
        };
        const getCurrentMouseFrame = (event: any): CursorData => {
            const rect = (
                d3.select('.cvat-timeline-box').node() as any
            )?.getBoundingClientRect() ?? event.target.getBoundingClientRect();
            if (rect.width === 0) {
                return {
                    frame: -1,
                    xLocal: xGlob,
                };
            }
            const newX = ((event.clientX - rect.left) / rect.width) * width;
            const xScaled = currentTransform.rescaleX(xGlob);
            const newFrame = Math.min(Math.max(Math.round(xScaled.invert(newX)), fstart), fstop);
            return {
                frame: newFrame,
                xLocal: xScaled,
            };
        };
        const getCurrentMouseZone = (event: any): number => {
            // Zone 0: 0% - 50% in height
            // Zone 1: 50% - 100% in height
            const rect = event.target.getBoundingClientRect();
            if (rect.height === 0) {
                return -1;
            }
            const newY = ((event.clientY - rect.top) / rect.height) * height;
            return Math.min(Math.max(Math.trunc(newY / (height / 2)), 0), 1);
        };
        const getZoneColor = (zone: number): string => (zone === 0 ?
            labelMap.get(selectedLabelID)?.color : imgAttrLabel.color) ?? 'black';
        const drawCursor = (xPos: number): void => {
            chart.selectAll('.cvat-timeline-cursor-line')
                .attr('x1', xPos)
                .attr('x2', xPos);
            chart.selectAll('.cvat-timeline-cursor-arrow')
                .attr('points', () => `${xPos - 5},8 ${xPos + 5},8 ${xPos},${xAxis.tickSize() + 10}`);
        };
        const setCursorColor = (color: string): void => {
            chart.selectAll('.cvat-timeline-cursor-line')
                .attr('stroke', color);
            chart.selectAll('.cvat-timeline-cursor-arrow')
                .attr('fill', color);
        };
        const moveCursor = async (event: any): Promise<void> => {
            const { frame, xLocal } = getCurrentMouseFrame(event);
            if (frame !== -1) {
                currentCursorPos = frame;
                const xCursor = xLocal(frame);
                drawCursor(xCursor);
                const zone = getCurrentMouseZone(event);
                const color = getZoneColor(zone);
                chart.selectAll('.cvat-timeline-track-interval')
                    .attr('x2', xCursor)
                    .attr('stroke', () => {
                        if (currentTrackStart !== null && currentTrackStart.zone === zone) {
                            return color;
                        }
                        return 'transparent';
                    });
                setCursorColor(color);
                await handleDrag(frame);
            }
        };

        // Zoomable and Translatable X axis (nothing on Y axis)
        const zoom = d3.zoom()
            .scaleExtent([1, Math.floor(N / 5)])
            .translateExtent([[0, 0], [width, height]])
            .extent([[0, 0], [width, height]])
            .on('zoom', (event: any) => {
                // X axis zoom
                const newX = event.transform.rescaleX(xGlob);
                const tickValues = newX.ticks(tickN).filter((tick: any) => Number.isInteger(tick));
                xAxis.scale(newX).tickValues(tickValues);
                chart.select('.x-axis').call(xAxis);
                chart.selectAll('.cvat-timeline-buffer rect')
                    .attr('x', (d: any) => newX(d.start))
                    .attr('width', (d: any) => newX(d.end) - newX(d.start));
                chart.selectAll('.cvat-timeline-indexed rect')
                    .attr('x', (d: any) => newX(d.start))
                    .attr('width', (d: any) => newX(d.end) - newX(d.start));
                chart.selectAll('.lines rect')
                    .attr('x', (d: any) => newX(d.start))
                    .attr('width', (d: any) => newX(d.end) - newX(d.start));
                // Array of 10 frames equally spaced between the start and end of the current view
                const frames = getSpacedIndices(newX);
                thumbnails.getMissing(frames);
                chart.selectAll('.cvat-timeline-thumbnails image')
                    .attr('x', (frame: number) => newX(frame));
                const xPos = newX(currentCursorPos);
                drawCursor(xPos);
                setTransform(event.transform);
                currentTransform = event.transform;
            });

        // Chart container
        const g = chart
            .attr('viewBox', [0, 0, width, height])
            .attr('class', 'cvat-timeline-box')
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .attr('tabindex', '0')
            .style('outline', 'none')
            .style('background-color', 'white')
            .call(zoom)
            .call(zoom.transform, currentTransform)
            .on('keydown', (event: any) => {
                if (event.ctrlKey) {
                    ctrlMode = true;
                    setCtrlPressed(true);
                    setCursorColor('blue');
                }
            })
            .on('keyup', async (event: any) => {
                if (!event.ctrlKey) {
                    currentTrackStart = null;
                    setTrackStart(null);
                    g.selectAll('.cvat-timeline-track-start').remove();
                    g.selectAll('.cvat-timeline-track-interval').remove();
                    ctrlMode = false;
                    setCtrlPressed(false);
                    setCursorColor('crimson');
                }
            })
            .on('mousedown', async (event: any) => {
                if (ctrlMode) {
                    let { frame: clickedFrame } = getCurrentMouseFrame(event);
                    if (clickedFrame === -1) {
                        if (currentCursorPos < 0) return;
                        clickedFrame = currentCursorPos;
                    }
                    const zone = getCurrentMouseZone(event);

                    if (currentTrackStart === null || zone !== currentTrackStart.zone) {
                        currentTrackStart = {
                            frame: clickedFrame,
                            zone,
                        };
                        setTrackStart(currentTrackStart);
                        const xPos = x(clickedFrame);
                        setCursorColor('magenta');
                        g.selectAll('.cvat-timeline-track-start').remove();
                        g.selectAll('.cvat-timeline-track-interval').remove();
                        g.append('polygon')
                            .attr('class', 'cvat-timeline-track-start blink')
                            .attr('points', () => `${xPos - 5},8 ${xPos + 5},8 ${xPos},${xAxis.tickSize() + 10}`);
                        g.append('line')
                            .attr('class', 'cvat-timeline-track-interval')
                            .attr('x1', xPos)
                            .attr('y1', xAxis.tickSize() + 8)
                            .attr('x2', xPos)
                            .attr('y2', xAxis.tickSize() + 8)
                            .attr('stroke', 'magenta')
                            .attr('stroke-width', 4);
                    } else {
                        const left = Math.min(currentTrackStart.frame, clickedFrame);
                        const right = Math.max(currentTrackStart.frame, clickedFrame);
                        if (zone === 0) {
                            await createTrack(selectedLabelID, left, right);
                        } else {
                            await createImgAttr(left, right);
                        }
                        currentTrackStart = null;
                        setTrackStart(null);
                    }
                }
            })
            .on('mousemove', async (event: any) => {
                if (ctrlMode) {
                    await moveCursor(event);
                }
            })
            .on('mouseenter', async (event: any) => {
                chart.node().focus();
                setCursorLock(true);
                setCtrlPressed(event.ctrlKey);
                setCursorColor(event.ctrlKey ? 'blue' : 'crimson');
                if (event.ctrlKey) {
                    await moveCursor(event);
                }
            })
            .on('mouseleave', async () => {
                chart.node().blur();
                setCursorLock(false);
            });

        // X axis
        g.append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0, ${xAxis.tickSize() + 10})`)
            .call(xAxis);

        // Buffered segments data
        g.append('g')
            .attr('class', 'cvat-timeline-buffer')
            .selectAll('rect')
            .data(bufferedSegments)
            .join('rect')
            .attr('x', (d: any) => x(d.start))
            .attr('y', height - 10)
            .attr('width', (d: any) => x(d.end + 1) - x(d.start))
            .attr('height', 10)
            .attr('fill', (d: any) => d.color);

        // Indexed segments data
        g.append('g')
            .attr('class', 'cvat-timeline-indexed')
            .selectAll('rect')
            .data(indexedSegments)
            .join('rect')
            .attr('x', (d: any) => x(d.start))
            .attr('y', height - 10)
            .attr('width', (d: any) => x(d.end + 1) - x(d.start))
            .attr('height', 10)
            .attr('fill', (d: any) => d.color);

        // Thumbnails
        if (thumbnails.display.state) {
            const frames = getSpacedIndices(x);
            thumbnails.getMissing(frames);
            g.append('g')
                .attr('class', 'cvat-timeline-thumbnails')
                .selectAll('image')
                .data(thumbnails.active.state)
                .join('image')
                .attr('x', (frame: number) => x(frame))
                .attr('y', xAxis.tickSize() + 40)
                .attr('width', thumbnailSize)
                .attr('height', thumbnailSize)
                .attr('xlink:href', (frame: number) => thumbnails.frames.get(frame));
        }

        // Data
        g.append('g')
            .attr('class', 'lines')
            .selectAll('rect')
            .data(data)
            .join('rect')
            .attr('x', (d: any) => x(d.start))
            .attr('y', (d: any) => d.line * 4 + xAxis.tickSize() + 12)
            .attr('width', (d: any) => x(d.end) - x(d.start))
            .attr('height', 2)
            .attr('fill', (d: any) => d.color)
            .attr('stroke', (d: any) => d3.color(d.color)?.darker(1))
            .on('mouseover', (event: any, d: Segment) => {
                if (ctrlMode) return;
                d3.select(event.target).attr('opacity', 0.5);
                const mouse = d3.pointer(event);
                const cardWidth = 200;
                const cardHeight = 70;
                const tooltipX = mouse[0] + 10 > width - cardWidth ? mouse[0] - cardWidth - 10 : mouse[0] + 10;
                const tooltipY = mouse[1] + 10 > height - cardHeight ? mouse[1] - cardHeight - 10 : mouse[1] + 10;
                const tooltip = g.append('g')
                    .attr('class', 'cvat-timeline-tooltip')
                    .attr('transform', `translate(${tooltipX}, ${tooltipY})`);
                tooltip.append('rect')
                    .attr('width', cardWidth)
                    .attr('height', cardHeight)
                    .attr('fill', 'white')
                    .attr('stroke', 'black')
                    .attr('stroke-width', 1)
                    .attr('rx', 5)
                    .attr('ry', 5);
                tooltip.append('text')
                    .attr('x', 10)
                    .attr('y', 20)
                    .attr('font-weight', 'bold')
                    .text(`${d.label}`);
                tooltip.append('text')
                    .attr('x', 10)
                    .attr('y', 40)
                    .text(d.value);
                tooltip.append('text')
                    .attr('x', 10)
                    .attr('y', 60)
                    .text(`${d.start} - ${d.end}`);
            })
            .on('mouseout', () => {
                g.selectAll('.cvat-timeline-tooltip').remove();
                d3.selectAll('rect').attr('opacity', 1);
            });

        // Frame cursor
        g.append('line')
            .attr('class', 'cvat-timeline-cursor-line')
            .attr('x1', x(cursorPos))
            .attr('y1', xAxis.tickSize() + 10)
            .attr('x2', x(cursorPos))
            .attr('y2', height)
            .attr('stroke', ctrlMode ? 'blue' : 'red')
            .attr('stroke-width', 2);
        const arrow = g.append('polygon')
            .attr('class', 'cvat-timeline-cursor-arrow')
            .attr('points', () => {
                const cursorX = x(cursorPos);
                return `${cursorX - 5},8 ${cursorX + 5},8 ${cursorX},${xAxis.tickSize() + 10}`;
            })
            .attr('fill', ctrlMode ? 'blue' : 'red');
        arrow.call(
            d3.drag()
                .on('drag', async (event: any) => {
                    const newX = event.x;
                    const xScaled = currentTransform.rescaleX(xGlob);
                    const newFrame = Math.min(Math.max(Math.round(xScaled.invert(newX)), fstart), fstop);
                    currentCursorPos = newFrame;
                    const xCursor = xScaled(newFrame);
                    drawCursor(xCursor);
                    await handleDrag(newFrame);
                })
                .on('start', (event) => {
                    event.sourceEvent.stopPropagation();
                    setCursorLock(true);
                })
                .on('end', () => {
                    setCursorLock(false);
                }),
        );
    }, [
        data, N,
        width, height, padding,
    ]);

    const ref = useD3((chart: any) => {
        if (annotation.annotations.history.undo.length === 0 && annotation.annotations.history.redo.length === 0) {
            chart.node().focus();
        }
        drawTimeline(chart);
        return () => chart.selectAll('*').remove();
    }, [data]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={21}>
                <div
                    className='cvat-timeline-container'
                    style={{
                        background: 'pink',
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%',
                        maxHeight: '30vh',
                    }}
                >
                    <svg ref={ref} />
                </div>
            </Col>
            <Col span={3}>
                <Row style={{ marginBottom: '10px' }}>
                    <Text strong>
                        Timeline controls
                    </Text>
                </Row>
                <Row style={{ marginBottom: '5px' }}>
                    <Col style={{ marginRight: '5px' }}>
                        <Text>Track label: </Text>
                    </Col>
                    <Col>
                        <CVATTooltip title='Change current label'>
                            <LabelSelector
                                size='small'
                                labels={normalLabels}
                                value={selectedLabelID}
                                onChange={(label) => {
                                    setSelectedLabelID(label.id);
                                }}
                            />
                        </CVATTooltip>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '5px' }}>
                    <Col style={{ marginRight: '5px' }}>
                        <Text>Imgattr: </Text>
                    </Col>
                    <Col>
                        <Select
                            size='small'
                            onChange={(value: string): void => {
                                setSelectedImgAttr(
                                    imgAttrs.find((attr: LabelAttribute) => attr.name === value) ?? imgAttrs[0],
                                );
                                setSelectedImgAttrValue('__undefined__');
                            }}
                            value={selectedImgAttr?.name}
                            className='cvat-timeline-select-imgattr'
                        >
                            {imgAttrs.map(
                                (attr: LabelAttribute): JSX.Element => (
                                    <Select.Option key={attr.name} value={attr.name}>
                                        {attr.name}
                                    </Select.Option>
                                ),
                            )}
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            size='small'
                            onChange={(value: string): void => {
                                setSelectedImgAttrValue(value);
                            }}
                            value={selectedImgAttrValue}
                            className='cvat-timeline-select-imgattr-value'
                        >
                            {selectedImgAttr?.values.map(
                                (value: string): JSX.Element => (
                                    <Select.Option key={value} value={value}>
                                        {value}
                                    </Select.Option>
                                ),
                            )}
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginRight: '5px' }}>
                        <Text>Show Thumbnails</Text>
                    </Col>
                    <Col style={{ marginRight: '5px' }}>
                        <CVATTooltip title='Toggle thumbnail background'>
                            <Checkbox
                                checked={thumbnails.display.state}
                                onClick={() => {
                                    thumbnails.display.setState(!thumbnails.display.state);
                                }}
                            />
                        </CVATTooltip>
                    </Col>
                    <Col>
                        {thumbnails.active.outdated() && (
                            <CVATTooltip title='Updating thumbnails'>
                                <LoadingOutlined />
                            </CVATTooltip>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
