// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';
import Icon, {
    StopOutlined, CheckCircleOutlined, LoadingOutlined, CloseOutlined, CheckOutlined,
} from '@ant-design/icons';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';

import AnnotationMenuContainer from 'containers/annotation-page/top-bar/annotation-menu';
import { UndoIcon, RedoIcon } from 'icons';
import { ActiveControl, ToolsBlockerState } from 'reducers';
import CVATTooltip from 'components/common/cvat-tooltip';
import customizableComponents from 'components/customizable-components';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import {
    Job, JobState,
} from 'cvat-core-wrapper';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import message from 'antd/lib/message';
import { updateJobAsync } from '../../../actions/jobs-actions';

interface Props {
    saving: boolean;
    undoAction?: string;
    redoAction?: string;
    undoShortcut: string;
    redoShortcut: string;
    drawShortcut: string;
    switchToolsBlockerShortcut: string;
    toolsBlockerState: ToolsBlockerState;
    activeControl: ActiveControl;
    keyMap: KeyMap;
    reviewing: boolean;
    jobInstance: Job;

    onSaveAnnotation(): void;
    onUndoClick(): void;
    onRedoClick(): void;
    onFinishDraw(): void;
    onSwitchToolsBlockerState(): void;
}

function LeftGroup(props: Props): JSX.Element {
    const {
        saving,
        keyMap,
        undoAction,
        redoAction,
        undoShortcut,
        redoShortcut,
        drawShortcut,
        switchToolsBlockerShortcut,
        activeControl,
        toolsBlockerState,
        onSaveAnnotation,
        onUndoClick,
        onRedoClick,
        onFinishDraw,
        onSwitchToolsBlockerState,
        reviewing,
        jobInstance,
    } = props;

    const includesDoneButton = [
        ActiveControl.DRAW_POLYGON,
        ActiveControl.DRAW_POLYLINE,
        ActiveControl.DRAW_POINTS,
        ActiveControl.AI_TOOLS,
        ActiveControl.OPENCV_TOOLS,
    ].includes(activeControl);

    const includesToolsBlockerButton =
        [ActiveControl.OPENCV_TOOLS, ActiveControl.AI_TOOLS].includes(activeControl) && toolsBlockerState.buttonVisible;

    const shouldEnableToolsBlockerOnClick = [ActiveControl.OPENCV_TOOLS].includes(activeControl);
    const SaveButtonComponent = customizableComponents.SAVE_ANNOTATION_BUTTON;

    const subKeyMap = {
        UNDO: keyMap.UNDO,
        REDO: keyMap.REDO,
    };

    const handlers = {
        UNDO: (event: KeyboardEvent | undefined) => {
            event?.preventDefault();
            if (undoAction) {
                onUndoClick();
            }
        },
        REDO: (event: KeyboardEvent | undefined) => {
            event?.preventDefault();
            if (redoAction) {
                onRedoClick();
            }
        },
    };
    // The following code is duplicated from components/annotation-page/top-bar/annotation-menu.tsx
    const dispatch = useDispatch();
    const updateJob = (job: Job): Promise<boolean> => dispatch(updateJobAsync(job));
    const history = useHistory();
    const finishJob = React.useCallback((state: JobState) => {
        // Changing behaviour to let the workflow automation rules decide when to move to the next stage
        // jobInstance.stage = JobStage.ACCEPTANCE;
        jobInstance.state = state;
        updateJob(jobInstance).then((success) => {
            if (success) {
                message.info('Job state updated', 2);
                history.push(`/tasks/${jobInstance.taskId}`);
            }
        });
    }, [jobInstance]);

    return (
        <>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            { saving && (
                <Modal
                    open
                    destroyOnClose
                    className='cvat-saving-job-modal'
                    closable={false}
                    footer={[]}
                >
                    <Text>CVAT is saving your annotations, please wait </Text>
                    <LoadingOutlined />
                </Modal>
            )}
            <Col className='cvat-annotation-header-left-group'>
                <AnnotationMenuContainer />
                <SaveButtonComponent
                    isSaving={saving}
                    onClick={saving ? undefined : onSaveAnnotation}
                    type='link'
                    className={saving ? 'cvat-annotation-header-save-button cvat-annotation-disabled-header-button' :
                        'cvat-annotation-header-save-button cvat-annotation-header-button'}
                />
                <CVATTooltip overlay={`Undo: ${undoAction} ${undoShortcut}`}>
                    <Button
                        style={{ pointerEvents: undoAction ? 'initial' : 'none', opacity: undoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-undo-button cvat-annotation-header-button'
                        onClick={onUndoClick}
                    >
                        <Icon component={UndoIcon} />
                        <span>Undo</span>
                    </Button>
                </CVATTooltip>
                <CVATTooltip overlay={`Redo: ${redoAction} ${redoShortcut}`}>
                    <Button
                        style={{ pointerEvents: redoAction ? 'initial' : 'none', opacity: redoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-redo-button cvat-annotation-header-button'
                        onClick={onRedoClick}
                    >
                        <Icon component={RedoIcon} />
                        Redo
                    </Button>
                </CVATTooltip>
                {reviewing && (
                    <>
                        <CVATTooltip overlay='Accept'>
                            <Button
                                type='link'
                                className='cvat-annotation-header-button'
                                onClick={() => {
                                    finishJob(JobState.COMPLETED);
                                }}
                                style={{
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    background: 'linear-gradient(90deg, #0f9b0f, #38a700)',
                                    color: 'white',
                                    margin: '2px 0',
                                    height: '44px',
                                    width: '44px',
                                }}
                            >
                                <CheckOutlined />
                            Accept
                            </Button>
                        </CVATTooltip>
                        <CVATTooltip overlay='Reject'>
                            <Button
                                type='link'
                                className='cvat-annotation-header-button'
                                onClick={() => {
                                    finishJob(JobState.REJECTED);
                                }}
                                style={{
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    background: 'linear-gradient(90deg, #c62828, #f44336)',
                                    color: 'white',
                                    margin: '2px 10px',
                                    height: '44px',
                                    width: '44px',
                                }}
                            >
                                <CloseOutlined />
                                Reject
                            </Button>
                        </CVATTooltip>
                    </>

                )}
                {includesDoneButton ? (
                    <CVATTooltip overlay={`Press "${drawShortcut}" to finish`}>
                        <Button type='link' className='cvat-annotation-header-done-button cvat-annotation-header-button' onClick={onFinishDraw}>
                            <CheckCircleOutlined />
                            Done
                        </Button>
                    </CVATTooltip>
                ) : null}
                {includesToolsBlockerButton ? (
                    <CVATTooltip overlay={`Press "${switchToolsBlockerShortcut}" to postpone running the algorithm `}>
                        <Button
                            type='link'
                            className={`cvat-annotation-header-block-tool-button cvat-annotation-header-button ${
                                toolsBlockerState.algorithmsLocked ? 'cvat-button-active' : ''
                            }`}
                            onClick={shouldEnableToolsBlockerOnClick ? onSwitchToolsBlockerState : undefined}
                        >
                            <StopOutlined />
                            Block
                        </Button>
                    </CVATTooltip>
                ) : null}
            </Col>
        </>
    );
}

export default React.memo(LeftGroup);
