import React, { useState } from 'react';
import { Job } from 'cvat-core/src/session';
import { Row } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import VideoAreaComponent from './video-area';

interface Props {
    instance: any;
    onUpdateJob(jobInstance: Job): void;
}

export default function VideoAreaEditor(props: Props): JSX.Element {
    const {
        instance: taskInstance,
        onUpdateJob,
    } = props;

    const [editing, setEditing] = useState(false);

    return (
        <div>
            <Row>
                <Text strong>Video area</Text>
            </Row>
            <Row>
                <Button
                    onClick={(): void => {
                        setEditing(!editing);
                    }}
                >
                    {editing ? 'Hide' : 'Edit'}
                </Button>
            </Row>
            <Row>
                {editing && <VideoAreaComponent task={taskInstance} onUpdateJob={onUpdateJob} />}
            </Row>
        </div>
    );
}
