// eslint-disable-next-line import/no-extraneous-dependencies
import * as cvatData from 'cvat-data';
import { useEffect, useState } from 'react';
import serverProxy from 'cvat-core/src/server-proxy';
import { useSelector } from 'react-redux';
import { CombinedState } from '../../../reducers';

export const THUMBNAIL_CHUNK_SIZE = 10_000;

interface ThumbnailsManager {
    getMissing(frames: number[]): void;

    frames: {
        state: Record<number, string>;
        setState: (thumbnails: Record<number, string>) => void;
        get: (frameNumber: number) => string;
    };
    display: {
        state: boolean;
        setState: (display: boolean) => void;
    };
    active: {
        state: number[];
        setState: (active: number[]) => void;
        outdated: () => boolean;
    };
}

const provider = new cvatData.FrameDecoder(
    cvatData.BlockType.ARCHIVE,
    1,
    12,
    cvatData.DimensionType.DIMENSION_2D,
    true,
);

export default function useThumbnails(): ThumbnailsManager {
    const instance = useSelector((state: CombinedState) => state.annotation.job.instance);
    const numberOfChunks = Math.floor((instance.stopFrame - instance.startFrame) / THUMBNAIL_CHUNK_SIZE) + 1;

    const [chunks, setChunks] = useState<Record<number, Uint8Array>>({});
    const [display, setDisplay] = useState<boolean>(true);
    const [thumbnails, setThumbnails] = useState<Record<number, string>>({});
    const [active, setActive] = useState<number[]>([]);
    const [missing, setMissing] = useState<number[]>([]);

    useEffect(() => {
        const controller = new AbortController();
        setChunks({});
        const startChunk = Math.floor(instance.startFrame / THUMBNAIL_CHUNK_SIZE);
        const stopChunk = Math.floor(instance.stopFrame / THUMBNAIL_CHUNK_SIZE);
        for (let i = startChunk; i <= stopChunk; i++) {
            serverProxy.frames.getThumbnail(null, instance.id, i).then((chunk) => {
                setChunks((prev) => ({ ...prev, [i]: chunk }));
            });
        }
        return () => controller.abort();
    }, [instance.id]);

    const getThumbnail = (frameNumber: number): void => {
        if (frameNumber in thumbnails || frameNumber < instance.startFrame || frameNumber > instance.stopFrame) {
            return;
        }
        const frame: any = provider.frame(frameNumber);
        if (frame !== null) {
            setThumbnails((prev) => ({ ...prev, [frameNumber]: frame }));
            return;
        }

        const chunkNumber = Math.floor(frameNumber / THUMBNAIL_CHUNK_SIZE);
        try {
            if (chunks[chunkNumber] === undefined) {
                return;
            }
            const chunk = chunks[chunkNumber];
            provider.requestDecodeBlock(
                chunk,
                frameNumber,
                frameNumber,
                () => null,
                () => {
                    const newFrame: any = provider.frame(frameNumber);
                    if (newFrame) {
                        setThumbnails((prev) => ({
                            ...prev,
                            [frameNumber]: newFrame,
                        }));
                    }
                },
                () => null,
            );
        } catch (error) {
            console.log(error);
        }
    };

    function getMissing(frames: number[]): void {
        if (!display) {
            return;
        }
        setMissing(frames);
    }

    useEffect(() => {
        let mounted = true;
        if (!display || Object.keys(chunks).length !== numberOfChunks) {
            return () => {
                mounted = false;
            };
        }
        missing.map((frame) => getThumbnail(frame));
        if (mounted) {
            const allFramesExtracted = missing.every((frame) => frame in thumbnails);
            if (allFramesExtracted && JSON.stringify(missing) !== JSON.stringify(active)) {
                setActive(missing);
            }
        }
        return () => {
            mounted = false;
        };
    }, [missing, Object.keys(thumbnails).length, Object.keys(chunks).length]);

    function getFrame(frameNumber: number): string {
        if (frameNumber in thumbnails) {
            return thumbnails[frameNumber];
        }
        return '';
    }

    function outdated(): boolean {
        return JSON.stringify(missing) !== JSON.stringify(active);
    }

    return {
        getMissing,
        frames: {
            state: thumbnails,
            setState: setThumbnails,
            get: getFrame,
        },
        display: {
            state: display,
            setState: setDisplay,
        },
        active: {
            state: active,
            setState: setActive,
            outdated,
        },
    };
}
