import Layout from 'antd/lib/layout';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { CombinedState } from 'reducers';
import { connect, useDispatch } from 'react-redux';
import serverProxy from 'cvat-core/src/server-proxy';
import { changeFrameAsync } from '../../../actions/annotation-actions';

interface StateToProps {
    taskId: number;
    frameNumber: number;
    startFrame: number;
    stopFrame: number;
    totalFrames: number;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            job: {
                instance: { taskId, startFrame, stopFrame },
            },
            player: {
                frame: { number: frameNumber },
            },
        },
    } = state;

    return {
        taskId,
        frameNumber,
        startFrame,
        stopFrame,
        totalFrames: stopFrame - startFrame + 1,
    };
}

type Props = StateToProps;

function QuickVideoPlayerWorkspaceComponent(props: Props): JSX.Element {
    const {
        taskId,
        frameNumber,
        startFrame,
        stopFrame,
        totalFrames,
    } = props;

    const dispatch = useDispatch();

    const [player, setPlayer] = useState<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        if (videoUrl) return;
        serverProxy.tasks.signedVideoUrl(taskId).then((response: any) => {
            const url = response?.url;
            if (!url) {
                alert('Failed to get signed video url');
            } else {
                setVideoUrl(url);
            }
        }).catch((error: any) => {
            console.log(error);
        });
    }, [taskId, videoUrl]);

    const handlePlayerRef = useCallback((playerRef: ReactPlayer) => {
        if (playerRef && !player) {
            setPlayer(playerRef);
        }
    }, [player]);

    useEffect(() => {
        if (!player || playing) return;
        player.seekTo(frameNumber / totalFrames);
    }, [frameNumber, player]);

    const handleProgress = async (played: number) => {
        // hack to prevent resetting to frame 0 when switching to video player workspace
        if (played === 0 && frameNumber > 30) return;

        const frame = Math.min(Math.round(played * totalFrames) + startFrame, stopFrame);
        if (frame !== frameNumber) {
            await dispatch(changeFrameAsync(frame));
        }
    };
    const seekToTargetFrame = () => {
        if (!player) return;
        const played = player.getCurrentTime() / player.getDuration();
        const target = frameNumber / totalFrames;
        if (Math.abs(played - target) < 0.01) return;
        player.seekTo(frameNumber / totalFrames);
    };

    return (
        <Layout hasSider className='cvat-quick-video-player-workspace'>
            <ReactPlayer
                id='cvat-quick-video-player'
                url={videoUrl}
                progressInterval={25}
                width='100%'
                height='70vh'
                onProgress={(e) => handleProgress(e.played)}
                onStart={() => setPlaying(true)}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                onBuffer={() => setPlaying(false)}
                onEnded={() => setPlaying(false)}
                onReady={seekToTargetFrame}
                controls
                ref={handlePlayerRef}
                style={{ zIndex: 1 }}
            />
        </Layout>
    );
}

export default connect(mapStateToProps)(QuickVideoPlayerWorkspaceComponent);
