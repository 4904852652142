import serverProxy, {
    ChunkQuality,
    decodeContextImages,
    decodeVideoChunks,
    IDBPreload,
} from 'cvat-core/src/server-proxy';

export async function preloadFramesOnDemand(
    signal: AbortSignal,
    jobInstance: any,
    progressCallback: (chunk: number, frameInChunk: number, chunkSize: number) => void,
): Promise<void> {
    const {
        id, stopFrame, startFrame, dataChunkSize, dataChunkType, mode,
    } = jobInstance;
    const numberOfChunks = Math.ceil((stopFrame - startFrame) / dataChunkSize);

    signal.addEventListener('abort', () => {
        throw new Error('Preloading was aborted');
    });

    const db = await IDBPreload.frameDBPool.getDB(id);
    const existingKeys = await IDBPreload.getAllIndexedKeys(db, 'frames');

    for (let chunkNumber = 0; chunkNumber < numberOfChunks; chunkNumber++) {
        if (signal.aborted) {
            throw new Error('Preloading was aborted');
        }

        const chunk = await serverProxy.frames.getData(id, chunkNumber, ChunkQuality.COMPRESSED, 0, true);
        const start = startFrame + chunkNumber * dataChunkSize;
        const end = Math.min(startFrame + (chunkNumber + 1) * dataChunkSize, stopFrame);
        const chunkSize = end - start;
        if (existingKeys?.includes(start) && existingKeys.includes(end - 1)) {
            let allFramesPreloaded = true;
            for (let frame = start; frame < end; frame++) {
                if (!existingKeys.includes(frame)) {
                    allFramesPreloaded = false;
                    break;
                }
            }
            if (allFramesPreloaded) {
                progressCallback(chunkNumber, chunkSize, chunkSize);
                continue;
            }
        }

        const decodedCallback = (frame: number) => {
            progressCallback(
                chunkNumber,
                frame,
                chunkSize,
            );
        };

        if (dataChunkType === 'video') {
            const meta = await serverProxy.frames.getMeta('job', id);
            await decodeVideoChunks(
                chunk,
                start,
                end,
                meta,
                mode,
                id,
                decodedCallback,
            );
        } else {
            await decodeContextImages(
                chunk,
                start,
                end,
                id,
                decodedCallback,
            );
        }
    }
}
